<template>
  <div>
    <v-icon color="yellow" @click="dialog = true">mdi-hand-coin</v-icon>
    <v-dialog v-model="dialog" persistent width="1200">
      <v-card>
        <v-card-title
          ><h3 class="display-2 font-weight-light">JMBComputers Payroll</h3>

          <v-spacer> </v-spacer>
          <v-img src="@/assets/jmbcomputers.png" max-width="200"></v-img>
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
          <v-row class="mt-2">
            <v-col cols="3" style="">
              <p class="font-weight-bold display-1">{{ item.name }}</p>
              <p class="mt-n4">
                Position:
                <span class="font-weight-bold">{{ item.position }}</span>
              </p>
              <p class="mt-n4">
                Daily Rate:
                <span class="font-weight-bold">{{ item.daily_rate }} Php.</span>
              </p>
              <p class="mt-n4">
                Date Registered:
                <span class="font-weight-bold">{{ item.created_at }}</span>
              </p>
            </v-col>
            <v-col cols="">
              <div class="d-flex">
                <v-select
                  :rules="rule"
                  label="Half"
                  prepend-icon=""
                  :items="['1st Half', '2nd Half']"
                  v-model="form.half"
                ></v-select>
                <v-select
                  class="ml-2"
                  :rules="rule"
                  label="Month"
                  :items="months"
                  v-model="form.month"
                ></v-select>

                <v-select
                  :rules="rule"
                  class="ml-2"
                  label="Year"
                  :items="years"
                  v-model="form.year"
                ></v-select>
              </div>
              <div class="d-flex">
                <v-checkbox
                  class="mr-4 ml-4"
                  label="Philhealth"
                  v-model="form.benefits"
                  value="Philhealth"
                ></v-checkbox>
                <v-checkbox
                  class="mr-4 ml-4"
                  label="Pag-ibig"
                  v-model="form.benefits"
                  value="Pag-ibig"
                ></v-checkbox>
                <v-checkbox
                  class="mr-4 ml-4"
                  label="SSS"
                  v-model="form.benefits"
                  value="SSS"
                ></v-checkbox>
              </div>
            </v-col>
            <v-col cols="4" class="mt-2 mb-2">
              <v-card>
                <v-card-text>
                  <h1 class="green--text">NetPay: {{ total_net }}</h1>
                  <v-text-field
                    type="number"
                    label="No. of Days"
                    prepend-icon="mdi-calendar"
                    v-model="form.total_days_worked"
                  ></v-text-field>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col class="">
              <h2>Other Income</h2>
              <v-text-field label="Description"></v-text-field>
              <v-text-field label="Amount" type="number"></v-text-field>
              <v-btn color="primary" small>Insert</v-btn>
            </v-col>
            <v-col>
              <h2>Cash Advances</h2>
              <v-data-table
                dense
                :items="cashAdvances"
                :headers="cashAdvancesHeaders"
              ></v-data-table>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <h3 class="mt-2 display-1">Payroll Summary</h3>
          <v-row class="mt-1">
            <v-col>
              <h3>Income</h3>
              <v-simple-table>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Description</th>
                    <th>Amount</th>
                  </tr>
                </thead>
              </v-simple-table>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <h3>Deductions</h3>
              <v-simple-table>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Description</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="dec in deductionsArr" :key="dec.id">
                    <td>{{ dec.id }}</td>
                    <td>{{ dec.name }}</td>
                    <td>{{ dec.amount }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>

          <h3 class="grey--text">Total Gross: {{ total_gross }}</h3>
          <h3 class="grey--text">Deductions: {{ totalDeductions }}</h3>
          <p class="grey--text">
            Notes: <span class="font-weight-bold">{{ form.notes }}</span>
          </p>
          <v-divider class="mt-2 mb-3"></v-divider>
          <h2>
            Total Net:
            <span class="success--text">{{ total_net }}</span>
          </h2>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit()" :loading="loading"
            >Submit</v-btn
          >
          <v-btn color="success" @click="print()">Print</v-btn>
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      cashAdvances: [],
      cashAdvancesHeaders: [
        { text: "Action", value: "action" },
        { text: "ID", value: "id" },
        { text: "Amount", value: "amount" },
        { text: "Date Inserted", value: "created_at" },
        { text: "Inserted", value: "user.username" },
      ],
      rule: [(v) => !!v || "Item is required"],
      form: {
        deductions: 0,
        notes: "none",
        benefits: ["Philhealth", "Pag-ibig", "SSS"],
      },
      dialog: false,
      loading: false,
      years: [2023, 2024, 2025, 2026],
      months: [
        "January",
        "Febuary",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  computed: {
    ...mapGetters({
      default_server_url: "auth/default_server_url",
    }),
    total_gross() {
      var total_gross = this.item.daily_rate * this.form.total_days_worked;
      return total_gross;
    },
    total_net() {
      var total_net = this.total_gross - this.form.deductions;
      const formattedNumber = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(total_net);
      return formattedNumber;
    },
    deductionsArr() {
      let dec_arr = [];
      let phil = { id: 1, name: "Philhealth", amount: 250 };
      let sss = { id: 2, name: "SSS", amount: 250 };
      let pagibig = { id: 3, name: "Pag-ibig", amount: 250 };
      if (this.form.benefits.includes("Philhealth")) dec_arr.push(phil);
      if (this.form.benefits.includes("SSS")) dec_arr.push(sss);
      if (this.form.benefits.includes("Pag-ibig")) dec_arr.push(pagibig);
      return dec_arr;
    },
    totalDeductions() {
      let totalAmount = 0;
      this.deductionsArr.forEach((item) => {
        totalAmount += item.amount;
      });
      return totalAmount;
    },
  },
  methods: {
    ...mapActions({
      add_wage: "wage/add_wage",
    }),
    print() {
      var url =
        this.default_server_url +
        "/" +
        "print_pay_slip" +
        "/" +
        this.item.id +
        "/" +
        this.form.half +
        "/" +
        this.form.month +
        "/" +
        this.form.year;
      window.open(url);
    },
    submit() {
      // add employee id to form
      this.form.employee_id = this.item.id;
      //   add total gross and net to form
      this.form.total_gross = this.total_gross;
      this.form.total_net = this.total_gross - this.form.deductions;
      //   check if some fields are empty
      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        this.loading = true;
        if (this.$refs.form.validate()) {
          this.add_wage(this.form)
            .then(() => {
              alert("Successfully inserted a wage");
              this.loading = false;
            })
            .catch((err) => {
              alert(err);
            });
        }
      }
      this.loading = false;
    },
  },
  created() {
    let pagibig = { id: 4, name: "Cash Advance", amount: 1500 };
    this.deductionsArr.push(pagibig);
  },
};
</script>

<style lang="scss" scoped></style>
