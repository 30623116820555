<template>
  <v-container class="mt-16">
    <div class="">
      <v-card flat v-if="ready">
        <v-card-title class="d-flex">
          <h2>Employees</h2>
          <v-spacer></v-spacer>
          <add-employee-modal />
        </v-card-title>
        <v-card-text class="pa-4">
          <!-- table -->
          <v-text-field
            v-model="search"
            label="Search"
            prepend-icon="mdi-magnify"
          ></v-text-field>
          <v-data-table
            :items="employees"
            :headers="employees_table"
            :search="search"
            dense
          >
            <template v-slot:item.is_active="{ item }">
              <span class="red--text" v-if="item.is_active == 0">Inactive</span>
              <span v-else class="green--text">Active</span>
            </template>
            <template v-slot:item.action="{ item }">
              <div class="d-flex">
                <edit-employee-modal :item="item" />
                <add-employee-wage-modal :item="item" />
                <v-btn icon color="red" @click="del(item)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </div>
            </template>
          </v-data-table>

          <h2>Wages Table</h2>
          <v-data-table dense :items="wages" :headers="wage_headers">
            <template v-slot:item.action="{ item }">
              <div class="d-flex">
                <v-btn color="success" @click="print(item)" icon
                  ><v-icon>mdi-printer</v-icon></v-btn
                >
                <v-btn color="red" @click="del(item)" icon
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import addEmployeeModal from "@/components/Employee/addEmployeeModal.vue";
import AddEmployeeWageModal from "../components/Employee/addEmployeeWageModal.vue";
import EditEmployeeModal from "../components/Employee/editEmployeeModal.vue";
export default {
  components: { addEmployeeModal, AddEmployeeWageModal, EditEmployeeModal },
  computed: {
    ...mapGetters({
      default_server_url: "auth/default_server_url",
      employees: "employee/employees",
      user: "auth/user",
      wages: "wage/wages",
    }),
  },
  methods: {
    ...mapActions({
      get_employees: "employee/get_employees",
      get_wages: "wage/get_wages",
      delete_employee: "employee/delete_employee",
    }),
    del(item) {
      let x = window.confirm("Are you sure you want to delete this employee?");
      if (x)
        this.delete_employee(item).then(() => {
          alert("Successfully deleted an employee");
        });
    },
    open_modal() {
      // check if admin
      if (this.user.role != "admin")
        alert("You are not allowed to access this page");
      else this.dialog = true;
    },
    print(item) {
      var url =
        this.default_server_url +
        "/" +
        "print_pay_slip" +
        "/" +
        item.employee_id +
        "/" +
        item.half +
        "/" +
        item.month +
        "/" +
        item.year;
      window.open(url);
    },
  },
  created() {
    if (this.employees.length == 0 || this.wages.length == 0) {
      this.get_employees().then(() => {
        this.ready = true;
        this.get_wages();
      });
    }
    this.ready = true;
  },
  data() {
    return {
      search: "",
      ready: false,
      wage_headers: [
        { text: "Action", value: "action" },
        { text: "ID", value: "id" },
        { text: "Employee Name", value: "employee.name" },
        { text: "Month", value: "month" },
        { text: "Year", value: "year" },
        { text: "Total Days Worked", value: "total_days_worked" },
        { text: "Deductions", value: "deductions" },
        { text: "Notes", value: "notes" },
        { text: "Total Gross", value: "total_gross" },
        { text: "Total Net", value: "total_net" },
        { text: "Created at", value: "created_at" },
      ],
      employees_table: [
        { text: "", value: "action" },
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Contact Number", value: "contact_no" },
        { text: "Address", value: "address" },
        { text: "Employment Date", value: "date_of_employment" },
        { text: "Position", value: "position" },
        { text: "Daily Rate", value: "daily_rate" },
        { text: "Total Wage Received", value: "total_wage_received" },
        { text: "Active Status", value: "is_active" },
      ],
      table_loading: true,
      dialog: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
